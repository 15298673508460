import React from "react";
import { useNavigate } from "react-router-dom";
import english from "../../static/icons/United-kingdom.png";
import greek from "../../static/icons/greece.png";
import "./EngApp.css";
import "../../App.css";
import CardImage from "../../logo.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import EngScan from "../../containers/EngScan";
import { useState } from "react";
import { ActionsContext } from "../../contexts/context";
import Loader from "../../loader.webp";
import { useRef } from "react";
import { Accordion } from "react-bootstrap";
import NFC from "../../logo-nfc.png";
import Search from "../../search.png";
import Error from "../../error.webp";
import Footer from "../../footer";
import { isAndroid } from "react-device-detect";
import { useParams } from "react-router-dom";

import { HideScroll } from "react-hide-on-scroll";

const EngApp = (props) => {
  const navigate = useNavigate();

  const params = useParams();
  const match = params.pathname === "";

  console.log(window.location.pathname);

  const myref = useRef(null);
  const myref1 = useRef(null);
  const [actions, setActions] = useState(null);
  const { scan } = actions || {};
  const actionsValue = { actions, setActions };
  const [Serial, setName] = useState("");
  const [message, setMessage] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [spinner, setDispSpinner] = useState(false);
  const [display, setDisplay] = useState(false);
  const [onclick, setCount] = useState(false);

  const [product0, setProduct0] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [product1, setProduct1] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [product2, setProduct2] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [ticket, setTicket] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });

  const [prod0, setProd0] = useState(false);
  const [prod1, setProd1] = useState(false);
  const [prod2, setProd2] = useState(false);
  const [tick, setTick] = useState(false);
  const [error, setError] = useState(false);
  const [notfound, setNotFound] = useState(false);

  let handleSubmit = async (e) => {
    setCount(false);
    setDisplay(false);
    setDisplayText(true);
    setDispSpinner(true);
    
      /*myref.current?.scrollIntoView({behavior: 'smooth'});*/
    
    myref.current?.scrollIntoView({ behavior: "smooth" });
    setDisplay("");

    e.preventDefault();

    setProd0(false);
    setProd1(false);
    setProd2(false);
    setTick(false);
    setError(false);

    try {
      setNotFound(false);
      if (window.location.pathname === "/en") {
        
          /*document.getElementById("ms").innerHTML = "";*/
        
        let res = await fetch("/en/card/RemainingDays/" + Serial.toUpperCase());

        let resJson = await res.json();
        if (res.status === 200) {
          if (resJson.Ticket) {
            setTicket({
              Activation: resJson.Ticket.activationTimestamp.split("T")[0],
              type: "Smart Ticket",
              product: resJson.Ticket.productId,
              remain: resJson.Ticket.journeys,
              lasttime: resJson.Ticket.usageTime.replace("T", " "),
              route: resJson.Ticket.usageRoute,
            });
            setTick(true);
          }
          if (resJson.product0) {
            setProduct0({
              Activation: resJson.product0.activationTimestamp.split("T")[0],
              type: "Smart Card",
              product: resJson.product0.productId,
              remain: resJson.product0.remaining,
              lasttime: resJson.product0.usageTime.replace("T", " "),
              route: resJson.product0.usageRoute,
            });
            setProd0(true);
          }
          if (resJson.product1) {
            setProduct1({
              Activation: resJson.product1.activationTimestamp.split("T")[0],
              type: "Smart Card",
              product: resJson.product1.productId,
              remain: resJson.product1.remaining,
              lasttime: resJson.product1.usageTime.replace("T", " "),
              route: resJson.product1.usageRoute,
            });
            setProd1(true);
          }
          if (resJson.product2) {
            setProduct2({
              Activation: resJson.product2.activationTimestamp,
              type: "Smart Card",
              product: resJson.product2.productId,
              remain: resJson.product2.remaining,
              lasttime: resJson.product2.usageTime.replace("T", " "),
              route: resJson.product2.usageRoute,
            });
            setProd2(true);
          }
          if (
            !resJson.Ticket &&
            !resJson.product0 &&
            !resJson.product1 &&
            !resJson.product2
          ) {
            setMessage("The Serial " + Serial + " not found");
            setTicket({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct0({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct1({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct2({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setNotFound(true);
          
            /*  console.log(resJson.Remaining); */
          }
        } else {
          if (resJson.detail) {
            setNotFound(true);
            setMessage("Serial not found");
          } else if (res.status === 429) {
            setMessage("Too Many Requests");
            setNotFound(false);
            setError(true);
          } else {
            setMessage("The service is not available");
            setError(true);
            setNotFound(false);
          }

          setDisplayText(true);
        }
      }
    } catch (err) {
      setTicket({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setProduct0({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setProduct1({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setProduct2({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setMessage("The service is not available");
      console.log(err);
      setDisplayText(true);
      setError(true);
    }

    setDispSpinner(false);
    setDisplay(true);
    
    /*myref.current?.scrollIntoView({behavior: 'smooth'});*/
    
    myref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onHandleAction = (actions) => {
    setTicket({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProduct0({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProduct1({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProduct2({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProd0(false);
    setProd1(false);
    setProd2(false);
    setTick(false);
    setDisplayText(false);
    setMessage("");
    setCount(false);
    setActions({ ...actions });
  };

  const select = document.querySelector(".l_button");

  select.addEventListener("change", (e) => {
    Array.from(select.options).forEach((option) => {
      option.style.backgroundImage = `url(${option.dataset.img})`;
    });
  });

  return (
    <>
      {/* Button*/}
      <div>
        <button className="l_button" onClick={() => navigate(-1)}>
          &nbsp; English &nbsp;
          <img className="language_icon" alt="language flag" src={english}></img>&nbsp;&nbsp;
        </button>
      </div>
      {/*<p>
            <select className="l_button" onChange={(event) => navigate(event.target.value)}>
              <option value="/en" data-img={greek}>English </option>
              <option value="/"data-img={greek}>Greek</option>
            </select>
          </p>*/}

      <div className="Appcontent">
        <div className="Smartcard">
          <img className="buscard_logo" src={CardImage} alt="logo" />
        </div>

        <div class="container">
          <div>
            <p>
              <form onSubmit={handleSubmit} className="form">
                <p className="input-container">
                  <div>
                    <div className="text_input">
                      <>Εnter the serial number of the card</>
                    </div>
                    <input
                      maxLength="17"
                      className="inputserial"
                      type="text"
                      value={Serial}
                      placeholder="     SERIAL NUMBER     "
                      onClick={() => setCount(true)}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <div className="divider">
                      <button1
                        id="button"
                        type="submit"
                        className="btn1"
                        onClick={handleSubmit}
                      >
                        &nbsp; &nbsp; SEARCH &nbsp;&nbsp;{" "}
                        <img
                          alt="search"
                          className="buttonimg"
                          src={Search}
                        ></img>
                      </button1>

                      {isAndroid && (
                        <button1
                          id="button1"
                          onClick={() => {
                            onHandleAction({ scan: "scanning", write: null });
                          }}
                          className="btn2"
                        >
                          NFC SCAN
                          <img
                            alt="Nfc Scan"
                            className="buttonimg"
                            src={NFC}
                          ></img>
                        </button1>
                      )}
                    </div>
                    {/*<img id="button1"  className="nfc"  src={NFC}  onClick={()=>onHandleAction({scan: 'scanning', write: null})}></img>*/}
                  </div>
                </p>
                {/*{spinner && (<p>Loading...</p>)}*/}
                <div ref={myref} class="output-container">
                  {spinner && (
                    <p id="mes" className="message">
                      Please wait...
                      <p>
                        <img
                          fetchpriority="high"
                          src={Loader}
                          alt="spinning log2"
                          className="loader-image"
                        />
                      </p>
                    </p>
                  )}
                  {!displayText && (
                    <div id="ms" className="Child">
                      <ActionsContext.Provider value={actionsValue}>
                        {scan && <EngScan />}
                      </ActionsContext.Provider>
                    </div>
                  )}

                  {display && tick && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" alt="Product" src={CardImage} />
                          </div>
                          PRODUCT : {ticket.product}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Type :</h>
                            <br /> {ticket.type}
                          </p>
                          <p>
                            <h className="product">Activation date :</h>
                            <br /> {ticket.Activation}
                          </p>

                          {ticket.lasttime && (
                            <p>
                              <h className="product">Charge date :</h> <br />
                              {ticket.lasttime}
                            </p>
                          )}
                          {ticket.lasttime && (
                            <p>
                              <h className="product">Route :</h>
                              <br /> {ticket.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Remaining Product :</h>
                            <br /> {ticket.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {display && prod0 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" alt="Product" src={CardImage} />
                          </div>
                          PRODUCT : {product0.product}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Type :</h>
                            <br /> {product0.type}
                          </p>
                          <p>
                            <h className="product">Activation Date :</h> <br />
                            {product0.Activation}
                          </p>

                          {product0.lasttime && (
                            <p>
                              <h className="product">Charge date :</h> <br />
                              {product0.lasttime}
                            </p>
                          )}
                          {product0.lasttime && (
                            <p>
                              <h className="product">Route :</h> <br />
                              {product0.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Remaining Product :</h>{" "}
                            <br />
                            {product0.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {display && prod1 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" alt="Product" src={CardImage} />
                          </div>
                          PRODUCT : {product1.product}{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Type : </h>
                            <br />
                            {product1.type}
                          </p>
                          <p>
                            <h className="product">Activation date :</h>
                            <br /> {product1.Activation}
                          </p>

                          {product1.lasttime && (
                            <p>
                              <h className="product">Charge date :</h>
                              <br /> {product1.lasttime}
                            </p>
                          )}
                          {product1.lasttime && (
                            <p>
                              <h className="product">Route :</h>
                              <br /> {product1.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Remaining Product :</h>
                            <br /> {product1.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {display && prod2 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" alt="Product" src={CardImage} />
                          </div>
                          PRODUCT : {product2.product}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Type :</h> <br />
                            {product2.type}
                          </p>
                          <p>
                            <h className="product">Activation Date :</h> <br />
                            {product2.Activation}
                          </p>

                          {product2.lasttime && (
                            <p>
                              <h className="product">Charge date :</h> <br />
                              {product2.lasttime}
                            </p>
                          )}
                          {product2.lasttime && (
                            <p>
                              <h className="product">Route :</h> <br />
                              {product2.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Remaining Product :</h>
                            <br /> {product2.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {error && (
                    <div id="mes3" className="message">
                      {message ? (
                        <p>
                          <img
                            fetchpriority="high"
                            className="img-error"
                            src={Error}
                            alt="error"
                          />
                          {message}
                        </p>
                      ) : null}
                      <p>Please try later</p>
                    </div>
                  )}

                  {notfound && (
                    <div ref={myref} id="mes3" className="message">
                      {message ? (
                        <p>
                          <img
                            fetchpriority="high"
                            className="img-error"
                            src={Error}
                            alt="error"
                          />
                          {message}
                        </p>
                      ) : null}
                    </div>
                  )}

                  <p>
                    {(tick || prod0 || prod1 || prod2) && (
                      <div className="message1">Serial Number : {Serial}</div>
                    )}
                  </p>
                  {/*<div className="content"><div id="mes" className="message">{display ? <p>{display}</p> : null}</div>
                            <div id="mes1" className="message">{product ? <p>{product}</p> : null}</div>  
                            <div id="mes3" className="message">{message ? <p>{message}</p> : null}</div>
                            <div id="mes2" className="message">{Remaing ? <p>{Remaing}</p> : null}</div>*/}
                </div>
              </form>
            </p>
          </div>
        </div>
        {/*
            <HideScroll variant="up" showOnPageInit={true}>
            
            {!display&&!spinner&&<div className='footer_main'><Footer/></div>}-->
            {display&&!spinner&&<div className='footer_main'><Footer/></div>}
            {display&&spinner&&<div className='footer_main'><Footer/></div>}
            </HideScroll>*/}
        <div ref={myref1}>
          <HideScroll variant="up" showOnPageInit={true}>
            {!onclick && (
              <div className="footer_main">
                <Footer />
              </div>
            )}
          </HideScroll>
        </div>
      </div>
    </>
  );
};
const rootElement = document.getElementById("root");
export default EngApp;
