import React from "react";
import { useNavigate } from "react-router-dom";
import Greek from "../../static/icons/greece.png";
import "./GrApp.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CardImage from "../../logo.webp";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Scan from "../../containers/Scan";
import { useState } from "react";
import { ActionsContext } from "../../contexts/context";
import Loader from "../../loader.webp";
import { useRef } from "react";
import { Accordion } from "react-bootstrap";
import NFC from "../../logo-nfc.png";
import Search from "../../search.png";
import Error from "../../error.webp";
import Footer from "../../footer";
import { isAndroid } from "react-device-detect";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
///import Card from "../Card";
import { HideScroll } from "react-hide-on-scroll";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import ParticlesBackground from "../ParticlesBg/particles-config";

const GrApp = (props) => {
  const navigate = useNavigate();

  console.log(window.location.pathname);

  const myref = useRef(null);
  const myref1 = useRef(null);
  const [actions, setActions] = useState(null);
  const { scan } = actions || {};
  const actionsValue = { actions, setActions };
  const [Serial, setName] = useState("");
  const [message, setMessage] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [spinner, setDispSpinner] = useState(false);
  const [display, setDisplay] = useState(false);
  const [onclick, setCount] = useState(false);

  const [product0, setProduct0] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [product1, setProduct1] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [product2, setProduct2] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [ticket, setTicket] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });

  const [prod0, setProd0] = useState(false);
  const [prod1, setProd1] = useState(false);
  const [prod2, setProd2] = useState(false);
  const [tick, setTick] = useState(false);
  const [error, setError] = useState(false);
  const [notfound, setNotFound] = useState(false);

  let handleSubmit = async (e) => {
    setCount(false);
    setDisplay(false);
    setDisplayText(true);
    setDispSpinner(true);
    {
      /*myref.current?.scrollIntoView({behavior: 'smooth'});*/
    }
    myref.current?.scrollIntoView({ behavior: "smooth" });
    setDisplay("");

    e.preventDefault();

    setProd0(false);
    setProd1(false);
    setProd2(false);
    setTick(false);
    setError(false);

    try {
      setNotFound(false);
      {
        /*document.getElementById("ms").innerHTML = "";*/
      }
      if (window.location.pathname == "/") {
        let res = await fetch("/card/RemainingDays/" + Serial.toUpperCase());

        let resJson = await res.json();
        if (res.status === 200) {
          if (resJson.Ticket) {
            setTicket({
              Activation: resJson.Ticket.activationTimestamp.split("T")[0],
              type: "Έξυπνο Εισιτήριο",
              product: resJson.Ticket.productId,
              remain: resJson.Ticket.journeys,
              lasttime: resJson.Ticket.usageTime.replace("T", " "),
              route: resJson.Ticket.usageRoute,
            });
            setTick(true);
          }
          if (resJson.product0) {
            setProduct0({
              Activation: resJson.product0.activationTimestamp.split("T")[0],
              type: "Έξυπνη Κάρτα",
              product: resJson.product0.productId,
              remain: resJson.product0.remaining,
              lasttime: resJson.product0.usageTime.replace("T", " "),
              route: resJson.product0.usageRoute,
            });
            setProd0(true);
          }
          if (resJson.product1) {
            setProduct1({
              Activation: resJson.product1.activationTimestamp.split("T")[0],
              type: "Έξυπνη Κάρτα",
              product: resJson.product1.productId,
              remain: resJson.product1.remaining,
              lasttime: resJson.product1.usageTime.replace("T", " "),
              route: resJson.product1.usageRoute,
            });
            setProd1(true);
          }
          if (resJson.product2) {
            setProduct2({
              Activation: resJson.product2.activationTimestamp,
              type: "Εξυπνη Κάρτα",
              product: resJson.product2.productId,
              remain: resJson.product2.remaining,
              lasttime: resJson.product2.usageTime.replace("T", " "),
              route: resJson.product2.usageRoute,
            });
            setProd2(true);
          }
          if (
            !resJson.Ticket &&
            !resJson.product0 &&
            !resJson.product1 &&
            !resJson.product2
          ) {
            setMessage("O Σειριακός " + Serial + " δεν βρέθηκε");
            setTicket({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct0({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct1({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct2({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setNotFound(true);
          }

          {
            /*  console.log(resJson.Remaining); */
          }
        } else {
          if (resJson.detail) {
            setNotFound(true);
            setMessage("O Σειριακός δεν βρέθηκε");
          } else if (res.status === 429) {
            setMessage("Έχετε υπερβεί το όριο χρήσης");
            setNotFound(false);
            setError(true);
          } else {
            setMessage("Η υπηρεσία δεν είναι διαθέσιμη");
            setError(true);
            setNotFound(false);
          }

          setDisplayText(true);
        }
      }
    } catch (err) {
      setTicket({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setProduct0({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setProduct1({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setProduct2({
        Activation: "",
        type: "",
        product: "",
        remain: "",
        card: "",
        lasttime: "",
        route: "",
      });
      setMessage("Η υπηρεσία δεν είναι διαθέσιμη");
      console.log(err);
      setDisplayText(true);
      setError(true);
    }

    setDispSpinner(false);
    setDisplay(true);
    {
      /*myref.current?.scrollIntoView({behavior: 'smooth'});*/
    }
    myref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onHandleAction = (actions) => {
    setTicket({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProduct0({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProduct1({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProduct2({
      Activation: "",
      type: "",
      product: "",
      remain: "",
      card: "",
      lasttime: "",
      route: "",
    });
    setProd0(false);
    setProd1(false);
    setProd2(false);
    setTick(false);
    setDisplayText(false);
    setMessage("");
    setCount(false);
    setActions({ ...actions });
    myref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {/* Button */}
      <div>
        <button className="l_button" onClick={() => navigate("/en")}>
          &nbsp; Ελληνικά &nbsp;
          <img className="language_icon" src={Greek}></img>&nbsp;&nbsp;
        </button>
      </div>
      {/*<p>
            <select className="l_button" onChange={(event) => navigate(event.target.value)}>
            <option value="/">Greek</option>
            <option value="/en">English</option>
              
            </select>
          </p>*/}

      {/*<div className="Appcontent">
        <div className="Smartcard">
          <div className="w-full bg-[#b1a29d] h-screen flex items-center justify-center">
            <div className="buscard_logo">
              <Card />
        </div>
            </div>
          </div>

          {/* <img className="buscard_logo" src={CardImage} alt="logo" />
        </div>*/}

      <div className="Appcontent">
        <div className="Smartcard">
          <img className="buscard_logo" src={CardImage} alt="logo" />
        </div>

        <div class="container">
          <div>
            <p>
              <form onSubmit={handleSubmit} className="form">
                <p className="input-container">
                  <div className="button">
                    <div className="text_input">
                      <>Εισάγετε τον σειριακό αριθμό της κάρτας</>
                    </div>
                    <input
                      maxLength="17"
                      className="inputserial"
                      type="text"
                      value={Serial}
                      placeholder="     ΣΕΙΡΙΑΚΟΣ ΑΡΙΘΜΟΣ     "
                      onClick={() => setCount(true)}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <div className="divider">
                      <button1
                        id="button"
                        type="submit"
                        className="btn1"
                        onClick={handleSubmit}
                      >
                        ΑΝΑΖΗΤΗΣΗ
                        <img
                          alt="search"
                          className="buttonimg"
                          src={Search}
                        ></img>
                      </button1>

                      {isAndroid && (
                        <button1
                          id="button1"
                          onClick={() => {
                            onHandleAction({ scan: "scanning", write: null });
                          }}
                          className="btn2"
                        >
                          NFC SCAN
                          <img
                            alt="Nfc Scan"
                            className="buttonimg"
                            src={NFC}
                          ></img>
                        </button1>
                      )}
                      {/*<button1 onClick={() => setIsScanning(false)} className="btn2">NFC SCAN <img alt="Nfc Scan" className="buttonimg" src={NFC}></img>
                          </button1>*/}
                    </div>
                    {/*<img id="button1"  className="nfc"  src={NFC}  onClick={()=>onHandleAction({scan: 'scanning', write: null})}></img>*/}
                  </div>
                </p>
                {/*{spinner && (<p>Loading...</p>)}*/}
                <div class="output-container">
                  {spinner && (
                    <p id="mes" className="message">
                      Παρακαλώ περιμένετε...
                      <p>
                        <img
                          fetchpriority="high"
                          src={Loader}
                          alt="spinning log2"
                          className="loader-image"
                        />
                      </p>
                    </p>
                  )}
                  {!displayText && (
                    <div id="ms" className="Child">
                      <ActionsContext.Provider value={actionsValue} ref={myref}>
                        {scan && <Scan />}
                      </ActionsContext.Provider>
                    </div>
                  )}

                  {display && tick && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" src={CardImage} />
                          </div>
                          ΠΡΟΪΟΝ : {ticket.product}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Τύπος :</h>
                            <br /> {ticket.type}
                          </p>
                          <p>
                            <h className="product">
                              Ημερομηνία Ενεργοποίησης :
                            </h>
                            <br /> {ticket.Activation}
                          </p>

                          {ticket.lasttime && (
                            <p>
                              <h className="product">Τελευταία χρήση :</h>{" "}
                              <br />
                              {ticket.lasttime}
                            </p>
                          )}
                          {ticket.lasttime && (
                            <p>
                              <h className="product">Διαδρομή :</h>
                              <br /> {ticket.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Υπόλοιπο :</h>
                            <br /> {ticket.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {display && prod0 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" src={CardImage} />
                          </div>
                          ΠΡΟΪΟΝ : {product0.product}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Τύπος :</h>
                            <br /> {product0.type}
                          </p>
                          <p>
                            <h className="product">
                              Ημερομηνία Ενεργοποίησης :
                            </h>{" "}
                            <br />
                            {product0.Activation}
                          </p>

                          {product0.lasttime && (
                            <p>
                              <h className="product">Τελευταία χρήση :</h>{" "}
                              <br />
                              {product0.lasttime}
                            </p>
                          )}
                          {product0.lasttime && (
                            <p>
                              <h className="product">Διαδρομή :</h> <br />
                              {product0.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Υπόλοιπο :</h> <br />
                            {product0.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {display && prod1 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" src={CardImage} />
                          </div>
                          ΠΡΟΪΟΝ : {product1.product}{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Τύπος : </h>
                            <br />
                            {product1.type}
                          </p>
                          <p>
                            <h className="product">
                              Ημερομηνία Ενεργοποίησης :
                            </h>
                            <br /> {product1.Activation}
                          </p>

                          {product1.lasttime && (
                            <p>
                              <h className="product">Τελευταία χρήση :</h>
                              <br /> {product1.lasttime}
                            </p>
                          )}
                          {product1.lasttime && (
                            <p>
                              <h className="product">Διαδρομή :</h>
                              <br /> {product1.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Υπόλοιπο :</h>
                            <br /> {product1.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {display && prod2 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="product">
                          <div className="div_product">
                            <img className="buscard_product" src={CardImage} />
                          </div>
                          ΠΡΟΪΟΝ : {product2.product}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            <h className="product">Τύπος :</h> <br />
                            {product2.type}
                          </p>
                          <p>
                            <h className="product">
                              Ημερομηνία Ενεργοποίησης :
                            </h>{" "}
                            <br />
                            {product2.Activation}
                          </p>

                          {product2.lasttime && (
                            <p>
                              <h className="product">Τελευταία χρήση :</h>{" "}
                              <br />
                              {product2.lasttime}
                            </p>
                          )}
                          {product2.lasttime && (
                            <p>
                              <h className="product">Διαδρομή :</h> <br />
                              {product2.route}
                            </p>
                          )}
                          <p ref={myref}>
                            <h className="product">Υπόλοιπο :</h>
                            <br /> {product2.remain}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}

                  {error && (
                    <div id="mes3" className="message">
                      {message ? (
                        <p>
                          <img
                            fetchpriority="high"
                            className="img-error"
                            src={Error}
                            alt="error"
                          />
                          {message}
                        </p>
                      ) : null}
                      <p>Παρακαλώ δοκιμάστε αργότερα</p>
                    </div>
                  )}

                  {notfound && (
                    <div ref={myref} id="mes3" className="message">
                      {message ? (
                        <p>
                          <img
                            fetchpriority="high"
                            className="img-error"
                            src={Error}
                            alt="error"
                          />
                          {message}
                        </p>
                      ) : null}
                    </div>
                  )}

                  <p>
                    {(tick || prod0 || prod1 || prod2) && (
                      <div className="message1">Σειριακός: {Serial}</div>
                    )}
                  </p>
                  {/*<div className="content"><div id="mes" className="message">{display ? <p>{display}</p> : null}</div>
                            <div id="mes1" className="message">{product ? <p>{product}</p> : null}</div>  
                            <div id="mes3" className="message">{message ? <p>{message}</p> : null}</div>
                            <div id="mes2" className="message">{Remaing ? <p>{Remaing}</p> : null}</div>*/}
                </div>
              </form>
            </p>
          </div>
        </div>
        {/*
            <HideScroll variant="up" showOnPageInit={true}>
            
            {!display&&!spinner&&<div className='footer_main'><Footer/></div>}-->
            {display&&!spinner&&<div className='footer_main'><Footer/></div>}
            {display&&spinner&&<div className='footer_main'><Footer/></div>}
            </HideScroll>*/}
        <div ref={myref1}>
          <HideScroll variant="up" showOnPageInit={true}>
            {!onclick && (
              <div className="footer_main">
                <Footer />
              </div>
            )}
          </HideScroll>
        </div>
      </div>
    </>
  );
};
const rootElement = document.getElementById("root");
export default GrApp;
