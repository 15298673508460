import React from "react";
import "./footer.css";
import logo from "./panoptima.webp";

const Footer = () => (
  <div className="fixed-bottom">
    <div
      className="text-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
    >
      © 2022 Copyright | Panoptima Technology Solutions
      <a className="text-reset" href="https://panoptima.gr/">
        <div>
          {" "}
          <img className="logo_img" src={logo} alt="logo" />
        </div>
      </a>
    </div>
  </div>
);

export default Footer;
