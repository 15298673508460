import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga";
import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GrApp from "./components/Greek/GrApp";
import EnApp from "./components/English/EngApp";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import ParticlesBackground from "./components/ParticlesBg/particles-config";

const TRACKING_ID = "G-1F0JZNNXZW"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const particlesInit = (engine) => {
    loadFull(engine);
  };

  return (
    <>
      {/*<div>
        <Particles
          init={particlesInit}
          options={ParticlesBackground}
          id="tsparticles"
          particlesLoaded="particlesLoaded"
        />
      </div>*/}
      <div className="App_cont">
        <Router>
          <Routes>
            <Route path="/" element={<GrApp />} />
            <Route path="/en" element={<EnApp />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
