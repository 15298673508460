import React, { useCallback, useContext, useEffect, useState } from "react";
import Scanner from "../components/EngScanner/EngScanner";
import { ActionsContext } from "../contexts/context";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRef } from "react";
import Loader from "../loader.webp";
import Error from "../error.webp";
import "./scan.css";
import CardImage from "../logo.webp";

const EngScan = () => {
  const [message, setMessage] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const { actions, setActions } = useContext(ActionsContext);
  const myref = useRef(null);
  const [Serial, setName] = useState("");
  //const [setPeriod] = useState("");
  const [spinner, setDispSpinner] = useState(false);
  //const [setProduct] = useState("");

  const [product0, setProduct0] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [product1, setProduct1] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [product2, setProduct2] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });
  const [ticket, setTicket] = useState({
    Activation: "",
    type: "",
    product: "",
    remain: "",
    card: "",
    lasttime: "",
    route: "",
  });

  const [prod0, setProd0] = useState(false);
  const [prod1, setProd1] = useState(false);
  const [prod2, setProd2] = useState(false);
  const [tick, setTick] = useState(false);
  const [error, setError] = useState(false);
  const [notfound, setNotFound] = useState(false);

  const scan = useCallback(async () => {
    if (window.location.pathname === "/en") {
      console.log("EN SCAN");
      if ("NDEFReader" in window) {
        try {
          const ndef = new window.NDEFReader();

          await ndef.scan();

          console.log("Scan started successfully.");
          ndef.onreadingerror = () => {
            console.log("Cannot read data from the NFC tag. Try another one?");
          };

          ndef.onreading = (event) => {
            console.log("NDEF message read.");
            onReading(event);

            setActions({
              scan: "scanned",
              write: null,
            });
            console.log("Scanning stopped En");
            ndef.stop();
            ndef.onreading = null;
          };
        } catch (error) {
          console.log(`Error! Scan failed to start: ${error}.`);
        }
      }
    }
  }, [setActions]);

  const onReading = async ({ message, serialNumber }) => {
    setNotFound(false);
    setSerialNumber(serialNumber.replaceAll(":", "").toUpperCase());
    setName(serialNumber.replaceAll(":", "").toUpperCase());
    setDispSpinner(true);
    setProd0(false);
    setProd1(false);
    setProd2(false);
    setTick(false);
    setError(false);
    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          const textDecoder = new TextDecoder(record.encoding);
          setMessage(textDecoder.decode(record.data));

          break;
        case "url":
          // TODO: Read URL record with record data.
          break;
        default:
        // TODO: Handle other records with record data.
      }
    }
    try {
      if (window.location.pathname === "/en") {
        let res = await fetch(
          "/en/card/RemainingDays/" +
            serialNumber.replaceAll(":", "").toUpperCase()
        );

        let resJson = await res.json();
        if (res.status === 200) {
          if (resJson.Ticket) {
            setTicket({
              Activation: resJson.Ticket.activationTimestamp.split("T")[0],
              type: "Smart Ticket",
              product: resJson.Ticket.productId,
              remain: resJson.Ticket.journeys,
              lasttime: resJson.Ticket.usageTime.replace("T", " "),
              route: resJson.Ticket.usageRoute,
            });
            setTick(true);
          }
          if (resJson.product0) {
            setProduct0({
              Activation: resJson.product0.activationTimestamp.split("T")[0],
              type: "Smart Card",
              product: resJson.product0.productId,
              remain: resJson.product0.remaining,
              lasttime: resJson.product0.usageTime.replace("T", " "),
              route: resJson.product0.usageRoute,
            });
            setProd0(true);
          }
          if (resJson.product1) {
            setProduct1({
              Activation: resJson.product1.activationTimestamp.split("T")[0],
              type: "Smart Card",
              product: resJson.product1.productId,
              remain: resJson.product1.remaining,
              lasttime: resJson.product1.usageTime.replace("T", " "),
              route: resJson.product1.usageRoute,
            });
            setProd1(true);
          }
          if (resJson.product2) {
            setProduct2({
              Activation: resJson.product2.activationTimestamp,
              type: "Smart Card",
              product: resJson.product2.productId,
              remain: resJson.product2.remaining,
              lasttime: resJson.product2.usageTime.replace("T", " "),
              route: resJson.product2.usageRoute,
            });
            setProd2(true);
          }
          if (
            !resJson.Ticket &&
            !resJson.product0 &&
            !resJson.product1 &&
            !resJson.product2
          ) {
            setMessage("The Serial " + Serial + " not found");
            setTicket({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct0({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct1({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setProduct2({
              Activation: "",
              type: "",
              product: "",
              remain: "",
              card: "",
              lasttime: "",
              route: "",
            });
            setNotFound(true);
          }

          
          /*  console.log(resJson.Remaining); */
          
        } else {
          if (resJson.detail) {
            setNotFound(true);
            setMessage("Serial not found");
          } else if (res.status === 429) {
            setMessage("Too Many Requests");
            setNotFound(false);
            setError(true);
          } else {
            setMessage("The service is not available");
            setError(true);
            setNotFound(false);
          }
          /*setProduct("");*/
          /*setPeriod("");*/
        }
      }
    } catch (err) {
      setMessage("The service is not available");
      console.log(err);
      setError(true);
    }
    {
      /*}myref.current?.scrollIntoView({behavior: 'smooth'});*/
    }
    myref.current?.scrollIntoView({ behavior: "smooth" });
    setDispSpinner(false);
  };

  useEffect(() => {
    if (window.location.pathname === "/en") scan();
  }, [scan]);

  return (
    <>
      {actions.scan === "scanned" ? (
        <div>
          {spinner && (
            <div id="mes" className="message">
              Please wait...
              <p>
                <img
                  fetchpriority="high"
                  src={Loader}
                  alt="spinning log2"
                  className="loader-image"
                />
              </p>
            </div>
          )}

          <p>
            {tick && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="product">
                    <div className="div_product">
                      <img className="buscard_product" alt="Product" src={CardImage} />
                    </div>{" "}
                    PRODUCT : {ticket.product}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <h className="product">Type :</h>
                      <br /> {ticket.type}
                    </p>
                    <p>
                      <h className="product">Activation Date :</h>
                      <br /> {ticket.Activation}
                    </p>

                    {ticket.lasttime && (
                      <p>
                        <h className="product">Charge date :</h>
                        <br /> {ticket.lasttime}
                      </p>
                    )}
                    {ticket.lasttime && (
                      <p>
                        <h className="product">Route :</h>
                        <br /> {ticket.route}
                      </p>
                    )}
                    <p ref={myref}>
                      <h className="product">Remaining PRODUCT :</h>
                      <br /> {ticket.remain}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            {prod0 && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="product">
                    <div className="div_product">
                      <img className="buscard_product" alt="Product" src={CardImage} />
                    </div>{" "}
                    PRODUCT : {product0.product}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <h className="product">Type :</h> {product0.type}
                    </p>
                    <p>
                      <h className="product">Activation Date :</h>
                      <br /> {product0.Activation}
                    </p>

                    {product0.lasttime && (
                      <p>
                        <h className="product">Charge date :</h>
                        <br /> {product0.lasttime}
                      </p>
                    )}
                    {product0.lasttime && (
                      <p>
                        <h className="product">Route :</h>
                        <br /> {product0.route}
                      </p>
                    )}
                    <p ref={myref}>
                      <h className="product">Remaining PRODUCT :</h>
                      <br /> {product0.remain}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            {prod1 && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="product">
                    <div className="div_product">
                      <img className="buscard_product" alt="Product" src={CardImage} />
                    </div>{" "}
                    PRODUCT : {product1.product}{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <h className="product">Type : </h>
                      <br />
                      {product1.type}
                    </p>
                    <p>
                      <h className="product">Activation Date :</h>
                      <br /> {product1.Activation}
                    </p>

                    {product1.lasttime && (
                      <p>
                        <h className="product">Charge date :</h>
                        <br /> {[product1].lasttime}
                      </p>
                    )}
                    {product1.lasttime && (
                      <p>
                        <h className="product">Route :</h>
                        <br /> {product1.route}
                      </p>
                    )}
                    <p ref={myref}>
                      <h className="product">Remaining PRODUCT :</h> <br />
                      {product1.remain}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            {prod2 && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="product">
                    <div className="div_product">
                      <img className="buscard_product" alt="Product" src={CardImage} />
                    </div>{" "}
                    PRODUCT : {product2.product}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <h className="product">Type :</h> {product2.type}
                    </p>
                    <p>
                      <h className="product">Activation Date :</h>
                      <br /> {product2.Activation}
                    </p>

                    {product2.lasttime && (
                      <p>
                        <h className="product">Charge date :</h>
                        <br /> {product2.lasttime}
                      </p>
                    )}
                    {product2.lasttime && (
                      <p>
                        <h className="product">Route :</h>
                        <br /> {product2.route}
                      </p>
                    )}
                    <p ref={myref}>
                      <h className="product">Remaining PRODUCT :</h>
                      <br /> {product2.remain}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </p>
          {error && (
            <div ref={myref} id="mes3" className="message">
              {message ? (
                <p>
                  <img
                    fetchpriority="high"
                    className="img-error"
                    src={Error}
                    alt="error"
                  />
                  {message}
                </p>
              ) : null}
              <p>Please try later</p>
            </div>
          )}
          {notfound && (
            <div ref={myref} id="mes3" className="message">
              {message ? (
                <p>
                  <img
                    fetchpriority="high"
                    className="img-error"
                    src={Error}
                    alt="error"
                  />
                  {message}
                </p>
              ) : null}
            </div>
          )}
          <p>
            {(tick || prod0 || prod1 || prod2) && (
              <div className="message1">Serial Number : {serialNumber}</div>
            )}
          </p>
        </div>
      ) : (
        <Scanner status={actions.scan}></Scanner>
      )}
    </>
  );
};
const rootElement = document.getElementById("root");
export default EngScan;
