import React, { useContext } from "react";
import "./Scanner.css";
import Spinner from "../../nfc2.webp";
import Spinner2 from "../../non-nfc.webp";
import { ActionsContext } from "../../contexts/context";
import { isChrome } from "react-device-detect";
import { isOpera } from "react-device-detect";
import { isAndroid } from "react-device-detect";
import Chrome from "../../chrome-logo.svg";
import Search from "../../search.webp";

const Scanner = () => {
  const { actions, setActions } = useContext(ActionsContext);
  return (
    <div className="scanner">
      <p
        className="scanner-exit"
        onClick={() => setActions({ ...actions, scan: null })}
      >
        X
      </p>
      <div className="scanner-container">
        {!isAndroid && (
          <div>
            <p className="text-attention">
              Η Λειτουργία υποστηρίζεται μόνο από Android{" "}
            </p>
            <div>
              <img
                fetchpriority="high"
                src={Spinner2}
                alt="spinning log"
                className="scanner-image"
              />
              <p className="text-minimal">
                Παρακαλώ εισάγετε τον Σειριακό της κάρτας στο πεδίο της
                αναζήτησης{" "}
                <img
                  fetchpriority="high"
                  src={Search}
                  className="image-search"
                  alt="Serial number"
                />
              </p>
            </div>
          </div>
        )}

        {isAndroid && !(isChrome || isOpera) && (
          <div>
            <p className="text-attention">
              Η Λειτουργία υποστηρίζεται μόνο από τον Google Chrome{" "}
              <img fetchpriority="high" src={Chrome} className="image" alt="attention not compatible"/>
            </p>
            <div>
              <img
                fetchpriority="high"
                src={Spinner2}
                alt="spinning log"
                className="scanner-image"
              />
              <p className="text-minimal">
                Παρακαλώ εισάγετε τον Σειριακό της κάρτας στο πεδίο της
                αναζήτησης{" "}
                <img
                  fetchpriority="high"
                  src={Search}
                  className="image-search"
                  alt="attention"
                />
              </p>
            </div>
          </div>
        )}

        {isAndroid && (isChrome || isOpera) && (
          <div>
            <img
              fetchpriority="high"
              src={Spinner}
              alt="spinning log"
              className="scanner-image"
            />
            <p className="scanner-text">Scanning...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scanner;
